import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';

import myNft from './utils/MyNft1.json';
//import myNft from './utils/MyNft2.json';
//import myNft from './utils/MyNFTDeltaSepolia.json'

import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';
import { Link, useSearchParams } from "react-router-dom";

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";

import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";

import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";

import { Spinner } from "./Spinner";
 
// ************************************************************************
// *********************** LAUNCH NOTES ***********************************
// ************************************************************************

// SEPOLIA <--> MAINNET CHANGE LIST:
// [ok] change MyNFT import atop...
// [ok] MINT PRICE CHANGE!!!
// [ok] all chain numbers, etc...
// [ok] connector.js chain ids...
// [ok] paymentButtonHTML values -- changed to payments or sandbox
// [ok] CONFIGS_NFTPAY_ID VALUES (changed to match sandbox)
// [ok] CONFIGS_NFTPAY_VERSION change to appropriate setting...

// THIS IS a TWO-SITE APP... SO:

    // 1. SET THESE ITEMS
    let SITE_VERSION = 1;    // 1 = 10k DIVAS, 2=DST DIVAS
    const CONFIGS_TOTALSUPPLY_CALC = 10000; // used for calculating --
    const CONFIGS_CONTRACT_ADDRESS = "0x98DAc54f0233D8ca3B9aC0d419b4cd7cB786d4F3";  // 10k DIVAS
    //const CONFIGS_CONTRACT_ADDRESS = "0xb344B936f66c04612643006D2195bc6269f0f056";  // 3k DSTDIVAS    
    //const CONFIGS_CONTRACT_ADDRESS = "0xebF6AF6a1c04E4373EF5d40A4B95Fa1b6A368dFc";  // SEPOLIA DSTDIVAS

     // 1b. IF IT's a 10k DIVA, comment out the 886-907 (or so) DIV...
     // 1b. IF IT's a 3k DST, then leave the DIV lines around 886-907

    // 2: SET NFT ABI ATOP THIS SCRIPT. (MyNFT1.json == 10k; MyNFT2.json == 3k DST set.)

    // 3. BUILD...

    // NOTES:
    // CONTRACT: ****IMPORTANT***  MAKE an affiliate called 'na' for this. upon launch.
    // APP:      ALSO SETUP THE REFERRERS here to match the contracts
    //               around lines 112 or so
    
// ************************************************************************
// ************************************************************************
// ************************************************************************

    // POP IN THE MERKLE API URI:   NOTE, I HAVE MULTIPLE ACTIVE ONES NOW, SO BE CAREFUL!
    let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api2.netlify.app/.netlify/functions/express/api/"; 

    let CONFIGS_DROPNAME = 'Divas';  // used in ALTs and so forth
    if (SITE_VERSION==2) { CONFIGS_DROPNAME = 'Delta Divas'; }

    let CONFIGS_DROPLOGO = 'divaslogo.png';
    if (SITE_VERSION==2) {  CONFIGS_DROPLOGO = 'deltalogo.png'; }

    let CONFIGS_FEATURED_GIF = 'featured.gif';
    if (SITE_VERSION==2) {  CONFIGS_FEATURED_GIF = 'featured2.gif'; }

    let CONFIGS_MINT_PRICE_CALC = 0.05; // used for calculating
    let CONFIGS_PROMO_PRICE_CALC = 0.05; // used for calculating
    let CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;

    let CONFIGS_TOTALSUPPLY_DISPLAY = '10,000'; // used for display purposes only...
    if (SITE_VERSION==2) { CONFIGS_TOTALSUPPLY_DISPLAY = '3,000'; }

    let CONFIGS_NFTS_RESERVED = 157; // I think we may need this to calculate SOLD OUT
    if (SITE_VERSION==2) { CONFIGS_NFTS_RESERVED = 23; }

    let CONFIGS_WALLET_LIMIT = 100; // High limit public, 10 presale..
    let CONFIGS_TRANSACTION_LIMIT = 7; // testing only! Actual settings tbd.

    let CONFIGS_CHAIN_ID = 1; // Chain ID:  1==MAINNET, 4==RINKEBY, 5==GOERLI, 11155111=SEPOLIA
    let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
    let CONFIGS_NETWORK = ''; //SB: blank (mainnet), 'goerli.'  'sepolia.' (w/ period) -- used for link building only
    let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
    let CONFIGS_NFT_TITLE_SINGULAR = 'Diva';
    if (SITE_VERSION==2) { CONFIGS_NFT_TITLE_SINGULAR = 'Delta Diva'; }

    let CONFIGS_NFT_TITLE_PLURAL = 'Divas';
    if (SITE_VERSION==2) { CONFIGS_NFT_TITLE_PLURAL = 'Delta Divas'; }

    let CONFIGS_URLS_FACEBOOK = 'https://www.facebook.com/profile.php?id=61554753998358&mibextid=ZbWKwL';
    let CONFIGS_URLS_TWITTER = 'https://twitter.com/divasbyblaq';
    let CONFIGS_URLS_INSTAGRAM = 'https://www.instagram.com/divasbyblaq/';
    let CONFIGS_URLS_YOUTUBE = '###';

    let CONFIGS_COLLECTION_SLUG = 'divas-by-blaq';
    if (SITE_VERSION==2) { CONFIGS_COLLECTION_SLUG = 'dst-divas-by-blaq'; }
    let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/'+CONFIGS_COLLECTION_SLUG;
    
    let CONFIGS_URLS_PROJECTWEBSITE = 'https://divasbyblaq.art/';
    let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;

    // ***************************************************************************
    // SPECIFY CC PROCESSOR HERE, as APP NEEDS TO WORK W MULTIPLE...
    let CONFIGS_CC_PROVIDER = 'CROSSMINT'; // can be 'NFTPAY' or 'CROSSMINT'
        // [] if CROSSMINT, IMPORT JS IN HEAD OF SITE; disable if not...

    let paymentButtonHTML;

        // SETTINGS FOR CROSSMINT HERE:
        let CONFIGS_XMINT_PROJECT_ID = '1cefc3cb-d73c-4d04-a7ca-c5c7108bf7ef'; //DIVAS and DELTAS (same)
        let CONFIGS_XMINT_COLLECTION_ID='e88e4b34-6d8b-48a0-a74e-48777214b20d';  //DIVAS
        if (SITE_VERSION==2) { CONFIGS_XMINT_COLLECTION_ID='4fa46880-d198-4594-aecc-1936d86fb292'; } //DELTA 3K
        let CONFIGS_XMINT_VERSION = 'production';  // can be 'staging' or 'production'

        // SETTINGS FOR NFTPAY HERE:
        let CONFIGS_NFTPAY_VERSION = 'payments';  // can be 'payments' or 'sandbox'
        let CONFIGS_NFTPAY_ID = '997c54e5-0230-447d-b3dc-a03e07ee5d07'; // DIVAS 10k
        if (SITE_VERSION==2) { CONFIGS_NFTPAY_ID = 'b40f2939-442e-4eea-abe8-25db520622de'; } // DELTA 3k
        // CONFIGS_NFTPAY_ID = 'be0484ba-1c0d-45ed-8dc2-85d52420ff5a'; // SANDBOX / SEPOLIA
        let xMintPrice;
        let xMintQuantity;
        let xMintTemp1;
        let xMintTemp2;
        let xMintHTML;

    // ***************************************************************************


    // PROMO/CC UPDATE
    //na, 0xC8d0fE959355100a62e77911C1fE05EEFd4F0bBf,0
    let CC_PROMO_CODES = ['na'];
    if (SITE_VERSION==2) { CC_PROMO_CODES = ['na']; }

    let CC_PURCHASES_ENABLED = 1; // 1 has CCBUYER, 0 hides it all (ETH only)
    let CC_PROJECT_ID = '';  // from Winter  usewinter.com
    let CC_PRODUCTION = false;  // false for testing, true for production

    // VARIOUS MESSAGES / STRINGS:
    let CONFIGS_ALLOW_LIST_MESSAGE = 'Sorry, this is an allowlist-only minting period and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.';

    let CONFIGS_BEGIN_MINT_ALERT = 'Working on your purchase now ... please click Okay and wait while we interface with the Ethereum blockchain. Another alert will popup shortly and let you know that your purchase is complete.';

    let CONFIGS_MINT_SUCCESS_ALERT = 'You have successfully purchased! Your digital collectible(s) should appear in your wallet and on OpenSea shortly. Thanks so much!!';

    // VARIOUS ERRORS FROM SOLIDITY:
    // Fill this object with some terms that would be found from our solidity contract's errors,
    // and then we can write custom responses for the alerts (but keep them all up here):
    let SOLIDITY_ERROR_LIST = {
        1: {
            'error': '[error text snippet from wallet error]',
            'response': '[alert response]'
        },
        2: {
            'error': 'exceed the wallet limit',
            'response': 'Your transaction would exceed the wallet limit.'
        },
        3: {
            'error': 'exceed the max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        4: {
            'error': 'exceed max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        5: {
            'error': 'Sale is not active',
            'response': 'The sale has been disabled on the smart contract.'
        },
        6: {
            'error': 'Not enough ether sent',
            'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
        },
        7: {
            'error': 'User denied transaction',
            'response': 'The user has denied the current transaction.'
        },
        8: {
            'error': 'already claimed',
            'response': 'It looks like you have already claimed your allowlist reserves.'
        },
        9: {
            'error': 'insufficient funds',
            'response': 'Insufficient funds. Please add enough ETH to your wallet for this purchase + gas.'
        },
        10: {
            'error': 'are not allowlisted',
            'response': 'Sorry, you are not on the presale list. Please contact the team if you believe this to be an error.'
        }, 
        11: {
            'error': 'exceed the transaction',
            'response': 'Sorry, you cannot purchase that many digital collectibles in a single transaction during this mint.'
        }, 
        12: {
            'error': 'cannot estimate gas',
            'response': 'There is an issue with gas estimation for this mint request.'
        } 
    }

    let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';

// ##################################################################################################
function floatify(number){
    return parseFloat((number).toFixed(10));
 }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return ( <Web3ReactProvider getLibrary = { getLibrary }><App/></Web3ReactProvider>) };

function openNFTPay() {
    document.getElementById("nftpayIframe").style.display = "block";
    document.getElementById("nftPayButton").style.display = "none";
    return;
}


const App = () => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
        console.log('running')
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    const [mintCount, setMintCount] = useState(0);
    const [value, setValue] = useState(1);
    const [myNFTs, setMyNFTs] = useState([]);
    const [trackMintCount, setTrackMintCount] = useState(0);
    const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
    const [isSold, setIsSold] = useState(false);
    const [isLibrary, setIsLibrary] = useState(false);
    const [mintPrice, setMintPrice] = useState(CONFIGS_CURRENT_PRICE); 
  
    // PROMO/CC UPDATE  TRYING NEW...
    const [promoCode, setPromoCode] = useState('na');  //PROMONEW
    const [errorMsgPromo, setErrorMsgPromo] = useState(''); //PROMONEW
    const [ccAffiliateFlag, setccAffiliateFlag] = useState(0); //CCBUYER
    const [ccAffiliateRef, setccAffiliateRef] = useState(''); //CCBUYER

    const [xmintConfigs, setXmintConfigs] = useState(''); //CROSSMINT

    let tokenId;
    let signer;
    let currentTotalSupply;

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log("Mint amount = " + value);

        // if using CROSSMINT:
        setXmintConfigs();

    };

// ***************************************    
// BEGIN NEW CROSSMINT MINT CODE -- MULTIPLE SECIONS
    // *** PARAM BUILDER ****
    useEffect(() => {

        let promoVarTemp;
        if (!promoCode) { promoVarTemp="na"; } else { promoVarTemp=promoCode;}
        console.log("PREP XMINT: promoVarTemp = " + promoVarTemp);
        
        xMintPrice = floatify(CONFIGS_CURRENT_PRICE * value);
        xMintHTML = '<crossmint-pay-button projectId="'+CONFIGS_XMINT_PROJECT_ID+'" collectionId="'+CONFIGS_XMINT_COLLECTION_ID+'"environment="'+CONFIGS_XMINT_VERSION+'" checkoutProps=\'{ \"display\": \"same-tab\", "paymentMethods": ["fiat"] }\' mintConfig=\'{ "type": "erc-721", "_amount": '+value+', "totalPrice": "'+xMintPrice+'", "affiliateRef": "'+promoVarTemp+'", "isAffiliate": "1" }\' class="xmint-btn" />';
        setXmintConfigs(xMintHTML);
        console.log('ue:XMINT-EFFECT: ' + xmintConfigs);
        return;      

    }, [xmintConfigs]);

// END NEW CROSSMINT MINT CODE -- MULTIPLE SECIONS
// ***************************************    


    // PROMO/CC UPDATE
    useEffect(() => {
        console.log('ue:AFFILIATE FLAG: ' + ccAffiliateFlag);
        return;      
    }, [ccAffiliateFlag]);

    // PROMO/CC UPDATE
    useEffect(() => {
        console.log('ue:AFFILIATE REF: ' + ccAffiliateRef);
        return;      
    }, [ccAffiliateRef]);

    // PROMO/CC UPDATE
    function applyPromoCode() {
        setupEventListener();
        console.log('apc: PROMO CODE: ' + promoCode);
        return;
    }

     // PROMO/CC UPDATE
     function ccBuyerApplyPromoCode(){

        // this is here to apply promo pricing for non-connected people (CC buyers)

        // if called, set this to normal price first...
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);   

        let thisItem;

        // then iterate and find cheaper price if possible...
        let wasMatch=0; 
        for (var i = 0; i < CC_PROMO_CODES.length; i++) {
            thisItem = CC_PROMO_CODES[i];
            console.log('nftp: THIS ITEM: ' + thisItem);
            if ( thisItem == promoCode) {
                setPromoCode(promoCode.toLowerCase());
                CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                setMintPrice(CONFIGS_CURRENT_PRICE);                
                console.log('nftp: code match: ' + thisItem);
                wasMatch=1;
                setccAffiliateFlag(1);
                setErrorMsgPromo("✅ Referral from " + promoCode + " active. nftp.");

                // MAINNET MAIN VERSION:
                if (CONFIGS_CC_PROVIDER == 'NFTPAY') {
                    paymentButtonHTML = '<iframe id="nftpayIframe" src="https://'+CONFIGS_NFTPAY_VERSION+'.nftpay.xyz/iframe/iframe_pay/'+CONFIGS_NFTPAY_ID+'?&isAffiliate=1&affiliateRef='+promoCode+'"></iframe>';
                }
                if (CONFIGS_CC_PROVIDER == 'CROSSMINT') {
                    paymentButtonHTML = '<p>CROSSMINT BUTTON HERE1</p>';
                }

            } 
        }
        if (wasMatch==0) { 

            // MAINNET MAIN VERSION:
            if (CONFIGS_CC_PROVIDER == 'NFTPAY') {
                paymentButtonHTML = '<iframe id="nftpayIframe" src="https://'+CONFIGS_NFTPAY_VERSION+'.nftpay.xyz/iframe/iframe_pay/'+CONFIGS_NFTPAY_ID+'?&isAffiliate=1&affiliateRef=na"></iframe>';
            }
            if (CONFIGS_CC_PROVIDER == 'CROSSMINT') {
                paymentButtonHTML = '<p>CROSSMINT BUTTON HERE2</p>';
            }

            setPromoCode(''); setccAffiliateFlag(0);
        }

        console.log('nftp: PROMO CODE: ' + promoCode);
        return;
    }    

    // PROMO/CC UPDATE
    useEffect(() => {
        let wasMatch=0; 
        let thisItem;
        let affiliateRef = searchParams.get("ref") || "";
        for (var i = 0; i < CC_PROMO_CODES.length; i++) {
            thisItem = CC_PROMO_CODES[i];
            console.log('nftp: THIS ITEM: ' + thisItem);
            if ( thisItem == affiliateRef) {
                setPromoCode(affiliateRef.toLowerCase());
                CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                setMintPrice(CONFIGS_CURRENT_PRICE);                
                console.log('nftp: code match: ' + thisItem);
                wasMatch=1;
                setccAffiliateFlag(1);
                setErrorMsgPromo("✅ Referral from " + affiliateRef + " active.");
                
                // MAINNET MAIN VERSION:
                if (CONFIGS_CC_PROVIDER == 'NFTPAY') {
                    paymentButtonHTML = '<iframe id="nftpayIframe" src="https://'+CONFIGS_NFTPAY_VERSION+'.nftpay.xyz/iframe/iframe_pay/'+CONFIGS_NFTPAY_ID+'?&isAffiliate=1&affiliateRef='+affiliateRef+'"></iframe>';
                }
                if (CONFIGS_CC_PROVIDER == 'CROSSMINT') {
                    paymentButtonHTML = '<p>CROSSMINT BUTTON HERE3</p>';
                }
    
            } 
        }
        if (wasMatch==0) {
            
            // MAINNET MAIN VERSION:
            if (CONFIGS_CC_PROVIDER == 'NFTPAY') {
                paymentButtonHTML = '<iframe id="nftpayIframe" src="https://'+CONFIGS_NFTPAY_VERSION+'.nftpay.xyz/iframe/iframe_pay/'+CONFIGS_NFTPAY_ID+'?&isAffiliate=1&affiliateRef=na"></iframe>';
            }
            if (CONFIGS_CC_PROVIDER == 'CROSSMINT') {
                             
                paymentButtonHTML= '<p>XMINT AREA 4</p>';

            }
            
            setPromoCode(''); setccAffiliateFlag(0);
        }

        if (!promoCode) {
            setErrorMsgPromo("");
            //setErrorMsgPromo("🔶 Referrals logged at mint (if valid).");
        }
        console.log('ue: PROMO CODE: ' + promoCode);
        return;      
    }, [promoCode]);


    const setupLibrary = async() => {

        try {

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup Library");

        } catch (error) {
            console.log(error);
        }
    }

    const setupEventListener = async() => {

        try {

            const wallet = library;
            signer = wallet.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            // event listener
            connectedContract.on("Mint", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log(from, tokenId.toNumber());
            });

            if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setMintCount(CONFIGS_TOTALSUPPLY_CALC);
                setIsSold(true);
            }
            console.log('mintCount: ' + mintCount);

            console.log('EventListener...'+searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

                affiliateRef = affiliateRef.toLowerCase();
                
                // maybe this is an on-page "apply"   ... if so, check...
                if (affiliateRef=='' && promoCode) {
                    affiliateRef=promoCode;
                }

                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('EventListener Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('EventListener Affiliate activated? : ' + isActive);    

                //[] THEN REPLACE THIS SECTION WITH BELOW:
                // DIVAS CHANGE: We don't care about commission for DIVAS, so,
                // changing next line from:  if (theCommission && isActive) {
                if (isActive) {
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                    setccAffiliateFlag(1);
                    setccAffiliateRef(affiliateRef);
                    setPromoCode(affiliateRef.toLowerCase());
                    setErrorMsgPromo("✅ Referral from " + affiliateRef + " active!");   
                    console.log('el: Affiliate Ref: ' + affiliateRef); 
                    console.log('el: Affiliate Flag...: ' + ccAffiliateFlag); 
                } else {
                    // SECTION  ADDED during CCBUYER changes...
                    CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;   
                    setMintPrice(CONFIGS_CURRENT_PRICE);       
                    setccAffiliateFlag(0);
                    setccAffiliateRef('');  
                    setPromoCode();
                    setErrorMsgPromo("");                           
                    //setErrorMsgPromo("🔶 Referrals logged at mint (if valid).");                           
                    console.log('el: MINT PRICE: : ' + mintPrice);    
                }

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup event listener!");

        } catch (error) {
            console.log(error);
        }
    }

    const askContractToMintNft = async() => {
        
        console.log('ChainID is: '+chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            let isAffiliate = false;
            console.log(searchParams);
            console.log(searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

            // if (!affiliateRef) { affiliateRef=promoCode;}
            // promo code takes precedence over ref in url..
            if (promoCode) { affiliateRef = promoCode; }

            // Cast to lowercase because we don't want case to be a problem...
            // Affiliates can share refs like Jim, jim, JIM, etc. and it'll still
            // work because we are enforcing all lowercase on the contract side.
            affiliateRef = affiliateRef.toLowerCase();
            console.log('Affiliate ref is:'+affiliateRef);

            if(affiliateRef){

                // ok looks like an affiliate sale.
                isAffiliate = true;
              
                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('Affiliate activated? : ' + isActive);

                // If eitehr of those tests fail, we will not pass this along to the mint function
                // as an affiliate sale because it would throw a needless error. Instead, we will
                // pass it as just a non-commission sale. Kthx.
                //if (theCommission==0 || isActive==false) { 
                    //affiliateRef=''; isAffiliate = false; 
                    //console.log('Mint passed to contract, but note that the user has likely come to a URL of an invalid affiliate because no fee structure was estabished on the smart contract and/or the affiliate was not activated on the contract.');
                //}
                // DIVAS ONLY -- Commissions of 0 are allowed.:
                if (isActive==false) { 
                    affiliateRef=''; isAffiliate = false; 
                    console.log('Mint passed to contract, but note that the user has likely come to a URL of an invalid affiliate because the affiliate was not activated on the contract.');
                }                


                // PROMO NEW
                if (theCommission && isActive) {
                    isAffiliate = true;
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                    setccAffiliateRef(affiliateRef);
                    setccAffiliateFlag(1);
                }

            }

            console.log("Public Minting: Popping wallet open now.")
            
            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Total Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.mint(value, isAffiliate, affiliateRef, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });

            console.log("Public Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Mint tx: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {
            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const allowlistMint = async() => {
        console.log(chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            const walletAdd = await signer.getAddress();
            console.log(String(walletAdd));
            const requestOptions = {
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": null,
                "method": "GET",
                "mode": "cors",
                "credentials": "omit"
            }

            let res = await fetch(CONFIGS_MERKLE_API + String(walletAdd), requestOptions);
            console.log(res);

            let merkleObject = await res.json();
            console.log(merkleObject);

            if (!merkleObject.valid) {
                alert(CONFIGS_ALLOW_LIST_MESSAGE);
                return;
            }

            console.log("Allowlist Mint: Popping wallet now to pay gas.");

            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Allowlist Ttl Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.allowlistMint(merkleObject.proof, value, {
                value: ethers.utils.parseUnits(totalPrice.toString())
            });
            
            console.log("Allowlist Minting: Please wait...")

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {

            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const getTotalNFTsMintedSoFar = async() => {

        try {

            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("Getting Mint Count")
            let mint_count = await connectedContract.totalSupply();
            console.log(ethers.utils.formatUnits(mint_count, 0));
            setMintCount(ethers.utils.formatUnits(mint_count, 0));

            console.log(`Set mint count is ${mint_count}`);

            console.log('Getting Minted NFTs');
            const userBalance = await connectedContract.balanceOf(account);
            console.log(userBalance);

            if (mint_count >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setIsSold(true);
            }

        } catch (error) {

            console.log(error)

        }
    }

    const getTotalOwnerNFTs = async() => {
        console.log('Show collection functionality removed.');
    }

    const onDisconnect = async() => {
        console.log("Killing the wallet connection", library);
        setIsLibrary(false);
        // disconnect wallet
        deactivate();
        console.log('disconnect acct: '+account);
        console.log('disconnect lib: '+library);
        console.log('disconnect conn: '+connector);
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);
        console.log('disconnect mint price: '+CONFIGS_CURRENT_PRICE);
        
    }

    useEffect(() => {
        setupLibrary();
    });

    useEffect(() => {
        if (library) { setupEventListener() };
    }, [isLibrary]);

    useEffect(() => {
        console.log(isLibrary);
        console.log(trackAnyMintCount);
        if (library) { getTotalNFTsMintedSoFar() }
    }, [isLibrary, trackAnyMintCount]);

    useEffect(() => {
        if (library) { getTotalOwnerNFTs() }
    }, [isLibrary, trackMintCount]);

    function checkDSTpwd() {
        var o = document.getElementById('dst_overlay_id');
        o.getElementsByTagName('form')[0].onsubmit = function() {
            let pwdInput = this.answer.value;
            // pwdInput = pwdInput.toLowerCase();
            if (pwdInput == 9) {
                o.style.display = "none";
            } else {
                alert('Oooooh, snap! Wrong answer!');
            }
            return false;
        };
    };    

    return (

        <div className="App">

        {/*  
            <div id="dst_overlay_id" class="dst_overlay_class">
                <form action="#">
                    <input name="answer" type="number" placeholder="Enter the number of Jewels."/> <button type="submit" onClick = { checkDSTpwd } >LFG!</button>
                </form>
                <div id="welcomeMessage">

                    <img className="wmdst" src="dstquestion.png" />

                    <p>OOO-OOP!! Thank you for visiting the page to purchase Delta Divas by BLAQ. While this is technically a public mint page we ask that only members of Delta Sigma Theta Sorority, Inc. purchase these NFTs and use them for their profile pics online. To gain access, enter the number of Jewels, above.</p>
                          
                    <p>YiD,<br />BLAQ Team Founders</p>

                    <p>ps Join on <a href={ CONFIGS_URLS_FACEBOOK } target="_blank">Facebook</a>, <a href={ CONFIGS_URLS_INSTAGRAM } target="_blank">Instagram</a> and/or <a href={ CONFIGS_URLS_TWITTER } target="_blank">X</a> to stay up to date and to claim your rewards.</p>

                    <p className="welcomeSocial">
                        <a className="welcomeButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_FACEBOOK }><img src="logo-facebook.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Facebook'} title={'Follow ' + CONFIGS_DROPNAME + ' on Facebook'} /></a> &nbsp;&nbsp;&nbsp;
                        <a className="welcomeButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_INSTAGRAM }><img src="logo-instagram.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} title={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} /></a> &nbsp;&nbsp;&nbsp;
                        <a className="welcomeButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }><img src="logo-twitter.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} title={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} /></a> 
                    </p>
                </div>
            </div>  
        */}

            <div className="header" >

                <div className="headerLeft">

                    <a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src={CONFIGS_DROPLOGO} className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a> 

                </div>

                <div className="headerRight">
                    
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }><img src="logo-twitter.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} title={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_FACEBOOK }><img src="logo-facebook.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Facebook'} title={'Follow ' + CONFIGS_DROPNAME + ' on Facebook'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_INSTAGRAM }><img src="logo-instagram.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} title={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} /></a> 
                    
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }><img src="logo-opensea.png" alt={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} title={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} /></a> 
                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }><img src="logo-etherscan.png" alt={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} title={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} /></a> 
                </div>

            </div>

            <div className="clear"> </div>

            <div className="mintBoxWrapper" >

                <div className="mintBoxLeft" >

                    <div className="allButMobile">
                        <img src={CONFIGS_FEATURED_GIF} alt={CONFIGS_DROPNAME} />
                    </div>

                </div>

                <div className= "mintBoxRight" >

                    <img className= "mobileOnly" src={CONFIGS_FEATURED_GIF} alt={CONFIGS_DROPNAME} />

                    <h2>Purchase {CONFIGS_DROPNAME} Here!</h2>
                    
                    <p className="mintBoxRightMessage">{CONFIGS_TOTALSUPPLY_DISPLAY} {CONFIGS_NFT_TITLE_PLURAL} @ { mintPrice } ETH</p> 

                    <div id="DAppArea" >

                    <p className="quantityQuestion"><span className="spotHighlight">Step 1:</span> Select How Many <br></br> {CONFIGS_NFT_TITLE_PLURAL} You Would Like:</p>

                    <div className="theSlider">
                        <input id="sliderVal"  className="slider"  onChange = { handleChange }  max={ CONFIGS_TRANSACTION_LIMIT } min="1" type="range"  value = { value } /> <br></br> Purchase { value } { ( value < 2 ) ? CONFIGS_NFT_TITLE_SINGULAR : CONFIGS_NFT_TITLE_PLURAL } 
                    </div>   

                    <div id="totalPrice" >Total Price:&nbsp;&nbsp;<b>{ (CONFIGS_CURRENT_PRICE * value).toFixed(4) }</b> ETH + gas </div>    


                    <hr></hr>

                    <p className="quantityQuestion"><span className="spotHighlight">Step 2:</span> Select a payment method:</p>

                    
                        {
                            (active === false) ? ( 

                                <div id="prepare">

                                    <h3>Use a Credit Card:</h3>

                                    {/* NFTPAY BUTTON... COMMENT OUT IF USING CROSSMINT  
                                    <button onClick = { openNFTPay } className="btn-primary pageButton" id="nftPayButton" type="button" >Purchase Using CC</button> 
                                    */}

                                    <div className="CCcenter">
                                        <div dangerouslySetInnerHTML={{__html: xmintConfigs}} />
                                    </div>
                                    {/* 
                                    <p>Note: CC purchasing is temporarily offline and will resume soon.</p>
                                    */}


                                    <h3>Or Use Ethereum:</h3>

                                    <button onClick = { openModal } className="btn-primary pageButton" id="connectButton" type="button" >Connect Ethereum Wallet</button> 

                                    <hr></hr>
                                    
                                    <p className="promoMessage1">{errorMsgPromo}</p>
                                    
{/*
                                    <h3>Got a Promo Code?</h3>
                                    <input id="promoArea" type="text" value = { promoCode } onChange = {e => setPromoCode(e.target.value.toLowerCase()) } placeholder = "enter code" /> <button onClick={ ccBuyerApplyPromoCode }> Apply Promo Code</button>
                                    <p>{ errorMsgPromo }</p>
*/}
                                    <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                        <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                                                                
                                        {
                                            Object.keys(connectorsByName).map(name => {
                                                const currentConnector = connectorsByName[name];
                                                const activating = currentConnector === activatingConnector;
                                                const connected = currentConnector === connector;

                                                return (
                                                    <button className="providerChoices" key={ name } onClick = { () => {
                                                            setActivatingConnector(currentConnector);
                                                            activate(connectorsByName[name]);
                                                            closeModal(); }}>
                                                        <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }> { activating && ( <Spinner color={"black"} style={{height:"25%", marginLeft: "-1rem" } } /> ) }
                                                        </div> 
                                                        <img className='logo-wallet' src={ connectorImagesByName[name] } alt="" /> 
                                                        <span className="walletName" > { name } </span>
                                                    </button>
                                                );

                                            })
                                        }

                                    </Modal>

                                </div>

                            ):(

                                (isSold === false) ? ( 

                                    <div>


                                        { /* PRESALE MINT BUTTON  
                                        <button onClick = { allowlistMint } className="btn-custom btn-primary pageButton" type="button" id="theMintButton" >PRESALE Mint { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL
                                        } </button> */ }
                                        

                                        { /* PUBLIC SALE MINT BUTTON.. DON'T FORGET TO ACTIVATE PUBLIC SALE IN THE CONTRACT!!!!!!!!!  */ }
                                        <button onClick={ askContractToMintNft } className="btn-custom btn-primary pageButton" type="button" id="theMintButton">Purchase { value } { ( value < 2 ) ? CONFIGS_NFT_TITLE_SINGULAR : CONFIGS_NFT_TITLE_PLURAL }
                                        </button> 
                                        

                                        {/* (!ccAffiliateFlag) ? ( <div><p className="promoMessage2"><a href="##" onClick={onDisconnect} >Got a promo code?</a></p></div> ) : (<></>) */}

                                        <div id="connected">

                                            <button onClick = { onDisconnect } className="btn btn-primary pageButton" id="disconnectButton" type="button">Disconnect <span class='smaller fixcaps'> 0x{ String(account).slice(2, 5) + "..." + String(account).slice(-4) }</span></button>

                                            <p className="buttonMessage">After purchasing, go to <a target="_blank" href={ 'http://'+ CONFIGS_OPENSEA_URL + '/' + account }>your wallet on OpenSea</a> to view.</p>

                                            <p className="sub-text">Project Mints:{ ' ' }<span id="currentMintCount">{ mintCount }</span> { ' ' } / { ' ' } { CONFIGS_TOTALSUPPLY_DISPLAY }</p>                                      


                                        </div> 

                                        <p className="promoMessage1">{errorMsgPromo}</p>

                                    </div>

                                ) : (

                                    <div id="totalPrice">COLLECTION IS SOLD OUT<br /><a href={CONFIGS_URLS_OPENSEA_COLLECTION}>Have a look on OpenSea</a> to grab a rare on the secondary market.</div>

                                )

                            )
                        }

                        <p><span className="soldFont" id="amountSold"> </span></p>

                        <p className="joinSocial">Join on <a href={ CONFIGS_URLS_FACEBOOK } target="_blank">Facebook</a>, <a href={ CONFIGS_URLS_INSTAGRAM } target="_blank">Instagram</a> and/or <a href={ CONFIGS_URLS_TWITTER } target="_blank">X</a> to stay up to date and to claim your rewards.</p>


                    </div>

                </div>

            </div>

            <div className="clear" > </div>

        </div>

    );

};